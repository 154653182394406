import { Link } from "react-router-dom";
import Button from "@material-ui/core/Button";

export const Progress = () => {
    return (
      <>
        <article>
          <h2>What is Progress tool?</h2>
          <p>
            <em> Coming up soon!</em> ipsom Lorem ipsum dolor sit amet consectetur adipisicing elit. Obcaecati quasi totam ducimus mollitia minus? Similique, quod accusantium! Minus nam quasi natus possimus modi. Accusantium, veniam. Ipsa perferendis nesciunt porro placeat.
          </p>
  
          <p>
      ipsum Lorem ipsum dolor sit amet consectetur adipisicing elit. Sed laborum numquam adipisci, voluptas sequi maxime maiores aliquam nostrum cumque modi quisquam ab, laudantium placeat, at consectetur nisi nesciunt atque quis.
          </p>
        </article>
        <hr />
        <article>
          <h2>More about</h2>
          <p>
       ipsum Lorem ipsum dolor, sit amet consectetur adipisicing elit. Quia ipsum quaerat quae unde autem repudiandae eaque, dignissimos consectetur fugit provident, magnam ullam ipsa architecto veritatis cumque est accusamus dolores similique!
          </p>
        </article>
        <section>
        <div className="call-to-action">
          <div>
            Sign up to create a FREE acount to customise and interact with the tools and make ulimited
            changes
            <br></br>
          </div>

          <div>
            <Button variant="contained" color="primary" size="large">
              {" "}
              <Link
                to="./signup"
                style={{ textDecoration: "none", color: "white" }}
              >
                Sign Up
              </Link>
            </Button>
          </div>
        </div>
      </section>
      </>
    );
  };
  