import React, { useState } from 'react';
import app from '../../util/firebase';

export default function Form() {
  const [title, setTitle] = useState('');


  const handleOnChange = (e) => {
      e.preventDefault();
    setTitle(e.target.value);
  
    
  };
  const createTodo = () => {
    const { currentUser } = app.auth();
  var user = currentUser

    // app.auth().onAuthStateChanged(function(user) {
      
      if (user != null) {
        var uid = user.uid;
        // app.database().ref('users').child(uid).set({ title: '' });
        const todoRef = app.database().ref('users').child(uid);
        const todo = {
          title,
          complete: false,
        };
         todoRef.push(todo);
    
      }
    // });
 

    setTitle("")
  };
  return (
    <div>

      <input type="text" onChange={handleOnChange} value={title} />
      <button onClick={createTodo} type="submit">Submit</button> 
     
     {/* <form onSubmit={createTodo}>
      
        <label>
          
          <input type="text" value={title} onChange={handleOnChange} />
        </label>
        <input type="submit" value="ADD ITEM"  />
      </form> */}
    </div>
  );
}