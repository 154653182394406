import DirectionsRunIcon from "@material-ui/icons/DirectionsRun";
import HomeSharpIcon from "@material-ui/icons/HomeSharp";
import HomeIcon from "@mui/icons-material/Home";
import React from "react";
import styled from "styled-components";
import { useState } from "react";
import Button from "@material-ui/core/Button";

import PersonIcon from "@material-ui/icons/Person";

import { useAuth } from "../contexts/AuthContext";
import { BrowserRouter as Router, Link, useHistory } from "react-router-dom";
import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";

const Nav = styled.nav`
  width: 100%;
  height: 100%;
  background-color: 	#000000;

  border-bottom: 2px solid #f1f1f1;
  padding: 10px;
  display: flex;
  justify-content: space-between;

  z-index: 3;
  @media (max-width: 1100px) {
    display: flex;

    flex-flow: column;
  }
  .logo {
    padding: 15px 0;
  }
`;

const StyledBurger = styled.div`
  width: 2rem;
  height: 2rem;
  position: fixed;
  top: 15px;
  right: 20px;

  display: none;
  @media (max-width: 1100px) {
    display: flex;
    justify-content: space-around;
    flex-flow: column nowrap;
    z-index: 20 !important ;
  }
  div {
    width: 2rem;
    height: 0.25rem;
    background-color: ${({ open }) => (open ? "#ccc" : "tomato")};
    color: coral;
    border-radius: 10px;
    transform-origin: 1px;
    transition: all 0.3s linear;
    &:nth-child(1) {
      transform: ${({ open }) => (open ? "rotate(45deg)" : "rotate(0)")};
    }
    &:nth-child(2) {
      transform: ${({ open }) => (open ? "translateX(100%)" : "translateX(0)")};
      opacity: ${({ open }) => (open ? 0 : 1)};
    }
    &:nth-child(3) {
      transform: ${({ open }) => (open ? "rotate(-45deg)" : "rotate(0)")};
    }
  }
`;

// wrappper around whole nav and small screen for burger menu
const Ul = styled.ul`
  list-style: none;
  list-style-type: none;
  text-decoration: none;
  font-size: large;
  color: #ffffff;
  display: flex;
  flex-flow: row nowrap;
  margin-bottom: 0px;
  bottom: 0px;
  margin-top: 10px;
  margin-left: 2px;
  margin-right: 2px;
  justify-content: space-evenly;
  min-width: 80%;

  li {
    padding: 10px 10px;
  }
  @media (max-width: 1100px) {
    display: flex;
    flex-flow: column wrap;
    align-items: center;
    background-color: beige;
    z-index: 3;
    position: fixed;
   
    justify-content: space-evenly;
    transform: ${({ open }) => (open ? "translateX(0)" : "translateX(100%)")};
    top: 0;
    right: 0;

    height: 60vh;
    width: 100px;
    padding-top: 3.5rem;
    padding-left: 5rem;
    transition: transform 0.3s ease-in-out;
    li {
      color: coral;
    }
  }
`;

//navbar elements css
const StyledLink = styled(Link)`
  text-decoration: none;
  color: coral;
  font-weight: bold;
  bottom: 5px;
  display: flex;
  justify-content: space-evenly;
  margin-bottom: 10px;
  margin-top: 10px;
  margin-left: 2px;
  margin-right: 2px;

  &:focus,
  &:hover,
  &:link,
  &:active {
    text-decoration: none;
    color: coral;
  }
`;

const Navbars = () => {
  const [open, setOpen] = useState(false);
  const { currentUser, logout } = useAuth();
  const [error, setError] = useState("");
  const history = useHistory();

  async function handleLogout() {
    setError(error);

    try {
      await logout();
      history.push("/login");
    } catch {
      setError("Failed to log out");
    }
  }
  return (
    <>
      <Nav>
        <span className="navbrand">
          <Link
            to="./"
            style={{ textDecoration: "none" }}
            onClick={() => setOpen(!open)}
          >
            <h1 style={{ fontSize: "xx-large" }}>
              <DirectionsRunIcon />
              Run-do-it
            </h1>
          </Link>
        </span>
        <StyledBurger open={open} onClick={() => setOpen(!open)}>
          <div />
          <div />
          <div />
        </StyledBurger>

        <Ul open={open}>
          {/* {!currentUser ? (
            <Link
              to="./"
              style={{ textDecoration: "none" }}
              onClick={() => setOpen(!open)}
            >
              {" "}
              <HomeIcon sx={{ margin: "12px", color: "red" }} />
            </Link>
          ) : (
            ""
          )}{" "} */}
          {currentUser ? (
            <StyledLink to="./user" onClick={() => setOpen(!open)}>
              {" "}
              <PersonIcon />
            </StyledLink>
          ) : (
            ""
          )}
          {!currentUser ? (
            <StyledLink to="./general" onClick={() => setOpen(!open)}>
              {" "}
              Get started
            </StyledLink>
          ) : (
            ""
          )}
          {currentUser ? (
            <DropdownButton id="dropdown-basic-button" title="Tools">
              <Dropdown.Item href="">
                <StyledLink to="./user" onClick={() => setOpen(!open)}>
                  {" "}
                  Rundoit
                </StyledLink>
              </Dropdown.Item>
              <Dropdown.Item href="">
                <StyledLink to="./progresspage" onClick={() => setOpen(!open)}>
                  {" "}
                  Health tool
                </StyledLink>
              </Dropdown.Item>
              <Dropdown.Item href="">
                <StyledLink to="./taskuser" onClick={() => setOpen(!open)}>
                  {" "}
                  Progress tool
                </StyledLink>
              </Dropdown.Item>
            </DropdownButton>
          ) : (
            ""
          )}
          {currentUser ? (
            <StyledLink to="./profile" onClick={() => setOpen(!open)}>
              {" "}
              Dashboard
            </StyledLink>
          ) : (
            ""
          )}
          <DropdownButton id="dropdown-basic-button" title="Resources">
            <Dropdown.Item href=""></Dropdown.Item>
            <Dropdown.Item href="">
              <StyledLink to="./about" onClick={() => setOpen(!open)}>
                {" "}
                About tools
              </StyledLink>
            </Dropdown.Item>
            <Dropdown.Item href="">
              <StyledLink to="./info" onClick={() => setOpen(!open)}>
                {" "}
                Guides
              </StyledLink>
            </Dropdown.Item>
          </DropdownButton>
          {!currentUser ? (
            <DropdownButton id="dropdown-basic-button" title="Features">
              <Dropdown.Item href="">
                <StyledLink to="./Rundoit" onClick={() => setOpen(!open)}>
                  {" "}
                  Rundoit
                </StyledLink>
              </Dropdown.Item>
              <Dropdown.Item href="">
                <StyledLink to="./progress-tool" onClick={() => setOpen(!open)}>
                  {" "}
                  Progress tool
                </StyledLink>
              </Dropdown.Item>
              <Dropdown.Item href="">
                <StyledLink to="./health-tool" onClick={() => setOpen(!open)}>
                  {" "}
                  Health tool
                </StyledLink>
              </Dropdown.Item>
            </DropdownButton>
          ) : (
            ""
          )}
          {/* {currentUser ? 
            <StyledLink  >
             <Button variant="contained"
                color="primary"
                style={{ left: "1px", bottom: "10px", color: "red" }}  onClick={handleLogout}>
          Log Out
        </Button>
            </StyledLink>
          : 
            ""
          } */}
          {!currentUser ? (
            <StyledLink to="./login" onClick={() => setOpen(!open)}>
              Sign In
            </StyledLink>
          ) : (
            ""
          )}
          {currentUser ? (
            ""
          ) : (
            <StyledLink to="./signup" onClick={() => setOpen(!open)}>
              <Button
                variant="contained"
                color="secondary"
                style={{ left: "1px", bottom: "10px" }}
              >
                Sign Up
              </Button>{" "}
            </StyledLink>
          )}
          {!currentUser ? (
            ""
          ) : (
            <StyledLink to="./signup" onClick={handleLogout}>
              <Button
                variant="contained"
                color="secondary"
                style={{ left: "1px", bottom: "10px" }}
              >
                Log Out
              </Button>{" "}
            </StyledLink>
          )}
        </Ul>
      </Nav>
    </>
  );
};

export default Navbars;
