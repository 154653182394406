import React, { useState } from 'react';

import db from '../../util/firebase';


export const ProgressForm = () => {
  const [score, setScore] = useState('');
  const [date, setDate] = useState('');

  const handleSubmit = async (event) => {
    const date = new Date();
const formattedDate = date.toISOString().substring(0, 10);
    event.preventDefault();
    try {
        await db.firestore().collection('progress').add({
            score: Number(score),
            date: formattedDate
        });
        console.log('Data added successfully');
    } catch (error) {
        console.error('Error adding data: ', error);
    }
};

  return (
    // <form onSubmit={handleSubmit}>
    //   <label>
    //     Score:
    //     <input type="number" value={score} onChange={(e) => setScore(e.target.value)} required />
    //   </label>
    //   <label>
    //     Date:
    //     <input type="date" value={date} onChange={(e) => setDate(e.target.value)} required />
    //   </label>
    //   <button type="submit">Submit</button>
    // </form>
    <form onSubmit={handleSubmit}>
    <label>
        Score:
        <input 
            type="number" 
            value={score} 
            onChange={(e) => setScore(e.target.value)}
            placeholder="Enter score" 
            required 
        />
    </label>
    <br />
    <label>
        Date:
        <input 
            type="date" 
            value={date} 
            onChange={(e) => setDate(e.target.value)}
            placeholder="Enter date" 
            required 
        />
    </label>
    <br />
    <button type="submit">Submit</button>
</form>
  );
};
