import React, { useState } from 'react';


function AddItem({submithandler})  {
  const [text, setText] = useState('')
  

  const changeHandler = e => {
    
   e.preventDefault();
    setText(e.target.value);  
    // console.log(text);
   
  }

  return ( 
    <div >
      {/* <input 
       style={{ height: 40, width: 440 , borderColor: 'gray', color: "black", borderWidth: 2 }}
      placeholder="add item..."
      onchange={changeHandler}
   
      value={text}
    
      /> */}
          <form onSubmit={(e) => {submithandler(text);  e.preventDefault(); setText('') }}>
      
        <label>
          
          <input type="text" value={text} onChange={changeHandler} />
        </label>
        <input type="submit" value="ADD ITEM"  />
      </form>
   
    </div>
  );
}
export default AddItem;