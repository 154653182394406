
import React, { useState, useEffect } from 'react';

import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend } from 'recharts';
import app from '../../util/firebase';

function TaskFeedback({ taskId }) {
    const [feedback, setFeedback] = useState([]);
    const [score, setScore] = useState('');
  
    useEffect(() => {
      const feedbackRef = app.database().ref(`tasks/${taskId}/feedback`);
  
      feedbackRef.on('value', (snapshot) => {
        const feedbackData = snapshot.val();
  
        if (feedbackData) {
          const feedbackArray = Object.entries(feedbackData).map(([key, value]) => {
            return { id: key, ...value };
          });
          setFeedback(feedbackArray);
        } else {
          setFeedback([]);
        }
      });
  
      return () => feedbackRef.off();
    }, [taskId]);
  
    const handleSubmit = (event) => {
      event.preventDefault();
  
      if (score) {
        const feedbackRef = app.database().ref(`tasks/${taskId}/feedback`);
        feedbackRef.push({ score, timestamp: Date.now() });
        setScore('');
      }
    };
  
    const handleScoreChange = (event) => {
      setScore(event.target.value);
    };
  
    const getChartData = () => {
      const chartData = [];
  
      feedback.forEach((feedbackItem) => {
        const feedbackDate = new Date(feedbackItem.timestamp).toLocaleDateString();
        const feedbackScore = feedbackItem.score;
  
        const chartDataIndex = chartData.findIndex((dataItem) => dataItem.name === feedbackDate);
  
        if (chartDataIndex === -1) {
          chartData.push({ name: feedbackDate, score: feedbackScore });
        } else {
          chartData[chartDataIndex].score += feedbackScore;
        }
      });
  
      return chartData;
    };
  
    return (
      <div>
        <h2>Task Feedback</h2>
        <form onSubmit={handleSubmit}>
          <label>
            Score:
            <input type="number" value={score} onChange={handleScoreChange} />
          </label>
          <button type="submit">Submit</button>
        </form>
        <LineChart width={500} height={300} data={getChartData()} margin={{ top: 5, right: 30, left: 20, bottom: 5 }}>
          <XAxis dataKey="name" />
          <YAxis />
          <CartesianGrid strokeDasharray="3 3" />
          <Tooltip />
          <Legend />
          <Line type="monotone" dataKey="score" stroke="#8884d8" activeDot={{ r: 8 }} />
        </LineChart>
      </div>
    );
  }
  
  export default TaskFeedback;


