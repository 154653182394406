import React, { useState, useEffect } from "react";

import app from "../../util/firebase";
import "../../App.css";
import Form from "./Form";

import DeleteIcon from "@material-ui/icons/Delete";
import Countdown from "../CountDown";
import TimerUp from "../TimerUP";


//this interface uses firebase realtime database and adds data through form.js

export default function TodoList() {
  const [todoList, setTodoList] = useState(null);
  const [showTime, setshowTime] = useState(false);
  const [rando, setRando] = useState("");
  const [mypick, setMyPick] = useState(25);
  const [clock, setClock] = useState(false);

  //this is to show items
  useEffect(() => {
    const { currentUser } = app.auth();
    var user = currentUser;
    var uid = user.uid;

    app
      .database()
      .ref("users")

      .child(`${uid}`)
      .on("value", (snapshot) => {
        if (snapshot.val() != null && user) {
          // console.log(uid)
          setTodoList({
            ...snapshot.val(),
          });
        }
      });
  }, []);

  const deleteTodo = (id) => {
    const { currentUser } = app.auth();
    var user = currentUser;
    var uid = user.uid;

    app
      .database()
      .ref("users")
      .child(uid)
      .on("value", (snapshot) => {
        if (snapshot.val() != null && user) {
          // console.log(uid)
          setTodoList({
            ...snapshot.val(),
          });
        }
      });

    const todoData = [];
    for (let i in todoList) {
      todoData.push({ i, ...todoList[i] });
    }
    //  setTodoList(todoData);
    console.log(todoList);
    var key = Object.values(todoData)[id].i;
    console.log(key);

    // app.database().ref(`users/${uid}/${key}`).set(null)
    app.database().ref("users").child(`${uid}/${key}`).remove();

    // window.location.reload();
  };

  const randomTodo = () => {
    const torandom = [];
    for (let i in todoList) {
      torandom.push({ i, ...todoList[i] });
    }
    console.log(torandom);

    //  var random = Object.values(torandom)[Math.floor(Math.random() * todoList.length)].i;
    var random = torandom[Math.floor(Math.random() * torandom.length)].title;
    // var random = Object.values(todoList)[id].i;

    console.log("type of ", typeof torandom);
    setRando(random);

    setTodoList(
      torandom.filter(function (el) {
        return el.title != random;
      })
    );
    console.log(torandom);
    setTimeout(() => {
      setClock(true);
    }, 2000);
    let maximum = 450;
    let minimum = 250;
    var randomnumber =
      Math.floor(Math.random() * (maximum - minimum + 1)) + minimum;
    console.log(randomnumber);
    setMyPick(randomnumber);
  };
  const startTodo = () => {
    const torandom = [];
    for (let i in todoList) {
      torandom.push({ i, ...todoList[i] });
    }
    console.log(torandom);

    //  var random = Object.values(torandom)[Math.floor(Math.random() * todoList.length)].i;
    var random = torandom[Math.floor(Math.random() * torandom.length)].title;
    // var random = Object.values(todoList)[id].i;
    console.log(random);
    setRando(random);
    setTodoList(
      torandom.filter(function (el) {
        return el.title != random;
      })
    );

    setTimeout(() => {
      setClock(true);
    }, 2000);
  };
  const flowTodo = () => {
    const torandom = [];
    for (let i in todoList) {
      torandom.push({ i, ...todoList[i] });
    }
    console.log(torandom);

    //  var random = Object.values(torandom)[Math.floor(Math.random() * todoList.length)].i;
    var random = torandom[Math.floor(Math.random() * torandom.length)].title;
    // var random = Object.values(todoList)[id].i;
    console.log(random);
    setRando(random);
    setTodoList(
      torandom.filter(function (el) {
        return el.title != random;
      })
    );

    setTimeout(() => {
      setshowTime(true);
    }, 2000);
  };
  const refreshPage = () => {
    setClock(false);
    setshowTime(false);
    setRando("");

    setMyPick(25);
    const { currentUser } = app.auth();
    var user = currentUser;
    var uid = user.uid;

    app
      .database()
      .ref("users")
      .child(`${uid}/Projects`)
      .child("bubba")
      .on("value", (snapshot) => {
        if (snapshot.val() != null && user) {
          // console.log(uid)
          setTodoList({
            ...snapshot.val(),
          });
        }
      });
  };
  // const completeTodo = () => {
  //   const todoRef = app.database().ref('users').child(todo.uid);
  //   todoRef.update({
  //     complete: !todo.complete,
  //   });
  // };

  return (
    <div>
      <h2 style={{ color: "blue" }}>{rando}</h2>

      {clock === true ? <Countdown until={mypick} /> : null}
      {showTime === true ? <TimerUp/> : null}
      <div className="refresh">
        <div class="tooltip-wrap">
          <button
            className="random-button"
            variant="contained"
            color="primary"
            onClick={() => {
              randomTodo();
            }}
          >
            RANDOM
          </button>
          <div class="tooltip-content">
            <p>Pick a random task and time</p>
          </div>
        </div>
        <div class="tooltip-wrap">
          <button
            className="start-button"
            variant="contained"
            color="default"
            onClick={() => {
              startTodo();
            }}
          >
            START
          </button>
          <div class="tooltip-content">
            <p>Start a random task with set time </p>
          </div>
        </div>
        <div class="tooltip-wrap">
          <button
            className="flow-button"
            variant="contained"
            color="primary"
            onClick={() => {
              flowTodo();
            }}
          >
            FLOW
          </button>
          <div class="tooltip-content">
            <p>Create flow state to complete task</p>
          </div>
        </div>

        <div class="tooltip-wrap">
          <button
            className="refresh-button"
            variant="contained"
            color="secondary"
            onClick={() => {
              refreshPage();
            }}
          >
            REFRESH
          </button>
          <div class="tooltip-content">
            <p>Refresh and start over</p>
          </div>
        </div>
      </div>


      <div>
        <Form />
        {todoList
          ? Object.keys(todoList).map((key, index) => (
              <div key={key} className="list_item">
                <ul>
                  <li className="flatlist">
                    <h1>{todoList[key].title}</h1>
                  </li>
                </ul>

                {/* <button onClick={() => {deleteTodo(index)}}>Delete</button>
                                    <button onClick={() => {completeTodo(index)}}>Complete</button> */}
                <DeleteIcon
                  fontSize="large"
                  onClick={() => {
                    deleteTodo(index);
                    console.log("delete pressed");
                  }}
                ></DeleteIcon>
              </div>
            ))
          : ""}
      </div>

      <div></div>
    </div>
  );
}
