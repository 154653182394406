import React, { useEffect, useState } from 'react';
import TaskForm from './TaskForm';
import TaskProgressBar from './TaskProgressBar';
import app from '../../util/firebase';

import TaskFeedback from './TaskFeedback';


function TaskPages() {
  const [tasks, setTasks] = useState([]);

  useEffect(() => {
    const tasksRef = app.database().ref('tasks');

    tasksRef.on('value', (snapshot) => {
      const tasksObject = snapshot.val();
      const tasksArray = Object.entries(tasksObject || {}).map(
        ([taskId, task]) => ({ ...task, id: taskId })
      );
      setTasks(tasksArray);
    });

    return () => {
      tasksRef.off();
    };
  }, []);

  return (
    <div>
      <h1>Task Page</h1>
      <TaskForm />
      <hr />
      {tasks.map((task) => (
        <div key={task.id}>
          <h2>Task {task.name}</h2>
          <TaskProgressBar taskId={task.id} />
          <TaskFeedback taskId={task.id} />
        </div>
      ))}
    </div>
  );
}

export default TaskPages;
