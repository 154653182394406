import React, { useState } from "react";
// import button from "@material-ui/core/button";
import AddItem from "./Form";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
// import List from '@material-ui/core/List';
import styled from "styled-components";
import Countdown from "../CountDown";

import TimerUp from "../TimerUP";

const TodoList = () => {
  const [rando, setRando] = useState("");
  const [clock, setClock] = useState(false);
  const [showTime, setshowTime] = useState(false);
  const [mypick, setMyPick] = useState(25);
  const [todoList, setTodoList] = useState([
    {
      id: "1",
      title: "Exercise",
    },
    {
      id: "2",
      title: "Read",
    },
    {
      id: "3",
      title: "Do Laundry",
    },
    {
      id: "4",
      title: "Go for walk",
    },
    {
      id: "5",
      title: "Study",
    },
  ]);

  const deleteTodo = (id) => {
    const todoData = [];
    for (let i in todoList) {
      todoData.push({ i, ...todoList[i] });
    }

    var key = Object.values(todoData)[id].i;

    console.log("key is", key);

    setTodoList((prevTodos) => {
      return prevTodos.filter((todoData) => todoList[key] !== todoData);
    });
  };

  const sumbitHandler = (text) => {
    console.log(text);
    setTodoList((prevTodos) => {
      return [{ title: text, id: Math.random().toString() }, ...prevTodos];
    });
  };

  const randomTodo = () => {
    const torandom = [];
    for (let i in todoList) {
      torandom.push({ i, ...todoList[i] });
    }
    console.log(torandom);

    //  var random = Object.values(torandom)[Math.floor(Math.random() * todoList.length)].i;
    var random = torandom[Math.floor(Math.random() * torandom.length)].title;
    // var random = Object.values(todoList)[id].i;
    console.log(random);
    setRando(random);
    setTodoList((prevTodos) => {
      return prevTodos.filter((todo) => todo.title !== random);
    });

    setTimeout(() => {
      setClock(true);
    }, 2000);
    let maximum = 25;
    let minimum = 45;
    var randomnumber =
      Math.floor(Math.random() * (maximum - minimum + 1)) + minimum;
    console.log(randomnumber);
    setMyPick(randomnumber);
  };
  const startTodo = () => {
    const torandom = [];
    for (let i in todoList) {
      torandom.push({ i, ...todoList[i] });
    }
    console.log(torandom);

    //  var random = Object.values(torandom)[Math.floor(Math.random() * todoList.length)].i;
    var random = torandom[Math.floor(Math.random() * torandom.length)].title;
    // var random = Object.values(todoList)[id].i;
    console.log(random);
    setRando(random);
    setTodoList((prevTodos) => {
      return prevTodos.filter((todo) => todo.title !== random);
    });

    setTimeout(() => {
      setClock(true);
    }, 2000);
  };
  const flowTodo = () => {
    const torandom = [];
    for (let i in todoList) {
      torandom.push({ i, ...todoList[i] });
    }
    console.log(torandom);

    //  var random = Object.values(torandom)[Math.floor(Math.random() * todoList.length)].i;
    var random = torandom[Math.floor(Math.random() * torandom.length)].title;
    // var random = Object.values(todoList)[id].i;
    console.log(random);
    setRando(random);
    setTodoList((prevTodos) => {
      return prevTodos.filter((todo) => todo.title !== random);
    });

    setTimeout(() => {
      setshowTime(true);
    }, 2000);
  };
  const refreshPage = () => {
    setClock(false);
    setshowTime(false);
    setRando("");
    // setData(todos);
    setTodoList((prevTodos) => {
      return [...prevTodos];
    });

    setMyPick(25);
  };

  return (
    <div>
      <h1 style={{ color: "black", fontSize: 40 }}>{rando}</h1>
      {clock === true ? <Countdown until={mypick} /> : null}
      {showTime === true ? <TimerUp /> : null}
      {/* <TimerUp /> */}
      <div className="refresh">
        <div class="tooltip-wrap">
          <button
            className="random-button"
            variant="contained"
            color="primary"
            onClick={() => {
              randomTodo();
            }}
          >
            RANDOM
          </button>
          <div class="tooltip-content">
            <p>Pick a random task and time</p>
          </div>
        </div>
        <div class="tooltip-wrap">
          <button
            className="start-button"
            variant="contained"
            color="default"
            onClick={() => {
              startTodo();
            }}
          >
            START
          </button>
          <div class="tooltip-content">
            <p>Start a random task with set time </p>
          </div>
        </div>
        <div class="tooltip-wrap">
          <button
            className="flow-button"
            variant="contained"
            color="primary"
            onClick={() => {
              flowTodo();
            }}
          >
            FLOW
          </button>
          <div class="tooltip-content">
            <p>Create flow state to complete task</p>
          </div>
        </div>

        <div class="tooltip-wrap">
          <button
            className="refresh-button"
            variant="contained"
            color="secondary"
            onClick={() => {
              refreshPage();
            }}
          >
            REFRESH
          </button>
          <div class="tooltip-content">
            <p>Refresh and start over</p>
          </div>
        </div>
      </div>
      <AddItem submithandler={sumbitHandler} />
      {todoList
        ? Object.keys(todoList).map((key, index) => (
            <div key={key} className="list_item">
              <ul>
                <li className="flatlist">
                  <h1>{todoList[key].title}</h1>
                </li>
              </ul>
              <DeleteForeverIcon
                fontSize="large"
                onClick={() => {
                  deleteTodo(index);
                  console.log("delete pressed");
                }}
              ></DeleteForeverIcon>

              {/* <button onClick={() => {deleteTodo(index)}}>Delete</button>
                                    <button onClick={() => {completeTodo(index)}}>Complete</button> */}
            </div>
          ))
        : ""}
      {/* <button onClick={() => {completeTodo()}}>Enter</button> */}
    </div>
  );
};
export default TodoList;
