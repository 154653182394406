import React, { useState, useEffect } from 'react';
import app from "../../util/firebase";
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';

function TaskFeedback({ taskId, uid }) {
  const [feedback, setFeedback] = useState([]);
  const [score, setScore] = useState('');
  const { currentUser } = app.auth();
  var user = currentUser;
  var uid = user.uid;
  
  useEffect(() => {
   
    const feedbackRef = app.database().ref(`users/${uid}/tasks/${taskId}/feedback`);

    feedbackRef.on('value', (snapshot) => {
      const feedbackData = snapshot.val();
      if (feedbackData) {
        const feedbackList = Object.values(feedbackData);
        setFeedback(feedbackList);
      } else {
        setFeedback([]);
      }
    });

    return () => {
      feedbackRef.off();
    };
  }, [taskId, uid]);

  const handleSubmit = (event) => {
    event.preventDefault();

    if (score) {
      const feedbackRef = app.database().ref(`users/${uid}/tasks/${taskId}/feedback`);
      feedbackRef.push({ score, timestamp: Date.now() });
      setScore('');
    }
  };

  const handleScoreChange = (event) => {
    setScore(event.target.value);
  };

  const getChartData = () => {
    const chartData = feedback.map((feedbackItem) => ({
      date: new Date(feedbackItem.timestamp).toLocaleDateString(),
      score: feedbackItem.score,
    }));

    return chartData;
  };

  return (
    <div>
      <h2>Task Feedback</h2>
      <form onSubmit={handleSubmit}>
        <label>
          Score:
          <input 
          type="number" 
          value={score} 
          onChange={handleScoreChange}
          style={{ border: '1px solid #ccc', borderRadius: '4px', padding: '5px' }}  />
        </label>
        <button type="submit">Submit</button>
      </form>
      {feedback.length > 0 ? (
        <ResponsiveContainer width="100%" height={300}>
          <LineChart data={getChartData()}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="date" />
            <YAxis />
            <Tooltip />
            <Line type="monotone" dataKey="score" stroke="#8884d8" strokeWidth={2} />
          </LineChart>
        </ResponsiveContainer>
      ) : (
        <p>No feedback available.</p>
      )}
    </div>
  );
}

export default TaskFeedback;