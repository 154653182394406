import React, { useEffect, useState } from 'react';
import app from '../../util/firebase';
import moment from 'moment';

function TaskProgressBar({ taskId }) {
  const [task, setTask] = useState(null);
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    const taskRef = app.database().ref(`tasks/${taskId}`);

    taskRef.on('value', (snapshot) => {
      setTask(snapshot.val());
    });

    return () => {
      taskRef.off();
    };
  }, [taskId]);

  useEffect(() => {
    if (task && task.start_time) {
      const startTime = moment(task.start_time);
      const endTime = moment();

      const totalDuration = moment.duration(endTime.diff(startTime));
      const elapsedDuration = moment.duration(endTime.diff(startTime));

      const totalDays = totalDuration.asDays();
      const elapsedDays = elapsedDuration.asDays();

      setProgress(Math.min(elapsedDays / totalDays, 1));
    }
  }, [task]);

  return (
    <div>
      <div
        style={{
          backgroundColor: 'blue',
          height: '20px',
          width: `${progress * 100}%`,
        }}
      />
      <div>
        {task && task.start_time && (
          <p>
            Started {moment(task.start_time).fromNow()} ({progress.toFixed(2)}{' '}
            days)
          </p>
        )}
      </div>
    </div>
  );
}

export default TaskProgressBar;
