import React from "react";

import { Link } from "react-router-dom";
import Button from "@material-ui/core/Button";

//home page
export const Landing = () => {
  return (
    <>
      <div className="hero">
        <section className="hero_section">
          <div className="hero_text">
            <h1>A hub of tools for productivity</h1>
            <h2>
              Tools that will help you discover new way of finishing your tasks and overcoming procrastination
            </h2>{" "}
            <br></br>
            <span>
              <br></br>
            </span>
          </div>{" "}
          <Button variant="contained" color="primary" size="large">
            {" "}
            <Link
              to="./general"
              style={{ textDecoration: "none", color: "white" }}
            >
              Get Started
            </Link>
          </Button>{" "}
        </section>
      </div>

      {/* <!-- ====== Features Section Start --> */}
      <section id="features" class="ud-pt-[100px]">
        <div class="ud-container">
          <div class="ud-flex ud-flex-wrap ud-mx-[-16px]">
            <div class="ud-w-full ud-px-4">
              <div
                class="
                ud-mx-auto ud-max-w-[655px] ud-text-center ud-mb-20
                wow
                fadeInUp
              "
                data-wow-delay=".1s"
              >
                <span
                  class="
                  ud-text-lg ud-font-semibold ud-text-primary ud-mb-2 ud-mt-5 ud-block
                "
                >
                  { "" }
                </span>
                <h2
                  class="
                  ud-text-black ud-font-bold ud-text-3xl
                  sm:ud-text-4xl
                  md:ud-text-[45px]
                  ud-mb-5
                "
                >
                  Core focus of the website
                </h2>
                <p
                  class="
                  ud-text-body-color ud-text-base
                  md:ud-text-lg
                  ud-leading-relaxed
                  md:ud-leading-relaxed
                  ud-max-w-[570px] ud-mx-auto
                "
                >
     The website focuses on helping users with productivity, well-being, and self-knowledge. It offers tools, resources, and information to enhance productivity and well-being, and to gain self-awareness. Features include articles, blog posts, interactive tools, assessments, webinars, e-books, and courses. Users can also connect with others, share tips, and discuss goals. Overall, it's a one-stop destination for improving productivity, well-being, and self-understanding.




                </p>
              </div>
            </div>
          </div>

          <div class="ud-pb-12 ud-border-b ud-border-[#E9ECF8]">
            <div class="ud-flex ud-flex-wrap ud-mx-[-16px]">
              <div class="ud-w-full md:ud-w-1/2 lg:ud-w-1/3 ud-px-4">
                <div
                  class="ud-mb-[70px] ud-text-center 2xl:ud-px-5 wow fadeInUp"
                  data-wow-delay=".15s"
                >
                  <div class="ud-mb-9 ud-flex ud-justify-center">
                    <svg
                      width="50"
                      height="50"
                      viewBox="0 0 50 50"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        opacity="0.5"
                        d="M27.2727 0.000488281V15.9096H34.0909L25 27.2732L15.9091 15.9096H22.7273V0.000488281H27.2727Z"
                        fill="#4A6CF7"
                      />
                      <path
                        d="M43.1818 0.000488281H34.0909V4.54594H43.1818C43.7846 4.54594 44.3627 4.78539 44.7889 5.21161C45.2151 5.63783 45.4545 6.21591 45.4545 6.81867V31.8187H31.8182V38.6369H18.1818V31.8187H4.54545V6.81867C4.54545 6.21591 4.7849 5.63783 5.21112 5.21161C5.63734 4.78539 6.21542 4.54594 6.81818 4.54594H15.9091V0.000488281H6.81818C5.00989 0.000488281 3.27566 0.71883 1.997 1.99749C0.718341 3.27614 0 5.01038 0 6.81867V43.1823C0 44.9906 0.718341 46.7248 1.997 48.0035C3.27566 49.2821 5.00989 50.0005 6.81818 50.0005H43.1818C44.9901 50.0005 46.7243 49.2821 48.003 48.0035C49.2817 46.7248 50 44.9906 50 43.1823V6.81867C50 5.01038 49.2817 3.27614 48.003 1.99749C46.7243 0.71883 44.9901 0.000488281 43.1818 0.000488281Z"
                        fill="#4A6CF7"
                      />
                    </svg>
                  </div>
                  <h3
                    class="
                    ud-font-bold ud-text-black ud-text-xl
                    sm:ud-text-2xl
                    lg:ud-text-xl
                    xl:ud-text-2xl
                    ud-mb-5
                  "
                  >
                    Rundoit 
                  </h3>
                  <p class="ud-text-body-color ud-text-base ud-leading-relaxed">
                  Boost your productivity

Get tasks out of your head quickly.

Simply enter tasks into the field and use rundoit to pick the task and avoid analysis paralysis.
                  </p>
                </div>
              </div>
              <div class="ud-w-full md:ud-w-1/2 lg:ud-w-1/3 ud-px-4">
                <div
                  class="ud-mb-[70px] ud-text-center 2xl:ud-px-5 wow fadeInUp"
                  data-wow-delay=".15s"
                >
                  <div class="ud-mb-9 ud-flex ud-justify-center">
                    <svg
                      width="50"
                      height="50"
                      viewBox="0 0 50 50"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M27.5265 0.000488281H2.11742C0.948003 0.000488281 0 0.948491 0 2.11791V33.8793C0 35.0487 0.948003 35.9967 2.11742 35.9967H27.5265C28.6959 35.9967 29.6439 35.0487 29.6439 33.8793V2.11791C29.6439 0.948491 28.6959 0.000488281 27.5265 0.000488281Z"
                        fill="#4A6CF7"
                      />
                      <path
                        opacity="0.5"
                        d="M48.4022 15.1783L34.5141 11.4199L33.4088 15.5087L45.2663 18.7145L38.0692 45.2839L13.541 38.6542L12.4378 42.743L39.0115 49.9274C39.2799 49.9998 39.5601 50.0187 39.8358 49.9829C40.1116 49.9471 40.3776 49.8573 40.6186 49.7187C40.8597 49.5801 41.0711 49.3953 41.2407 49.175C41.4104 48.9547 41.535 48.7031 41.6074 48.4346L49.8929 17.7743C50.0393 17.2324 49.9645 16.6545 49.685 16.1677C49.4054 15.681 48.9441 15.3251 48.4022 15.1783Z"
                        fill="#4A6CF7"
                      />
                    </svg>
                  </div>
                  <h3
                    class="
                    ud-font-bold ud-text-black ud-text-xl
                    sm:ud-text-2xl
                    lg:ud-text-xl
                    xl:ud-text-2xl
                    ud-mb-5
                  "
                  >
                    Health tool
                  </h3>
                  <p class="ud-text-body-color ud-text-base ud-leading-relaxed">
                  Explore the concept of self-growth with tools to keep track of impact of new things you try.
                  </p>
                </div>
              </div>
              <div class="ud-w-full md:ud-w-1/2 lg:ud-w-1/3 ud-px-4">
                <div
                  class="ud-mb-[70px] ud-text-center 2xl:ud-px-5 wow fadeInUp"
                  data-wow-delay=".2s"
                >
                  <div class="ud-mb-9 ud-flex ud-justify-center">
                    <svg
                      width="50"
                      height="50"
                      viewBox="0 0 50 50"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M18.1818 20.455H2.27273C1.66996 20.455 1.09188 20.2156 0.665665 19.7894C0.239446 19.3631 0 18.7851 0 18.1823V2.27322C0 1.67045 0.239446 1.09237 0.665665 0.666153C1.09188 0.239934 1.66996 0.000488281 2.27273 0.000488281H18.1818C18.7846 0.000488281 19.3627 0.239934 19.7889 0.666153C20.2151 1.09237 20.4545 1.67045 20.4545 2.27322V18.1823C20.4545 18.7851 20.2151 19.3631 19.7889 19.7894C19.3627 20.2156 18.7846 20.455 18.1818 20.455Z"
                        fill="#4A6CF7"
                      />
                      <path
                        d="M18.1818 50.0004H2.27273C1.66996 50.0004 1.09188 49.761 0.665665 49.3348C0.239446 48.9086 0 48.3305 0 47.7277V31.8186C0 31.2159 0.239446 30.6378 0.665665 30.2116C1.09188 29.7853 1.66996 29.5459 2.27273 29.5459H18.1818C18.7846 29.5459 19.3627 29.7853 19.7889 30.2116C20.2151 30.6378 20.4545 31.2159 20.4545 31.8186V47.7277C20.4545 48.3305 20.2151 48.9086 19.7889 49.3348C19.3627 49.761 18.7846 50.0004 18.1818 50.0004Z"
                        fill="#4A6CF7"
                      />
                      <path
                        opacity="0.5"
                        d="M27.2727 2.27319H50V6.81865H27.2727V2.27319ZM27.2727 13.6368H50V18.1823H27.2727V13.6368ZM27.2727 31.8186H50V36.3641H27.2727V31.8186ZM27.2727 43.1823H50V47.7277H27.2727V43.1823Z"
                        fill="#4A6CF7"
                      />
                    </svg>
                  </div>
                  <h3
                    class="
                    ud-font-bold ud-text-black ud-text-xl
                    sm:ud-text-2xl
                    lg:ud-text-xl
                    xl:ud-text-2xl
                    ud-mb-5
                  "
                  >
                      Progress Tool
                  </h3>
                  <p class="ud-text-body-color ud-text-base ud-leading-relaxed">
                

Make informed decisions about new approaches.
Give daily ratings on their effectiveness to determine what works best for you.
                  </p>
                </div>
              </div>
              <div class="ud-w-full md:ud-w-1/2 lg:ud-w-1/3 ud-px-4">
                <div
                  class="ud-mb-[70px] ud-text-center 2xl:ud-px-5 wow fadeInUp"
                  data-wow-delay=".15s"
                >
                  
           
                 
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- ====== Features Section End --> */}

      <section>
        <div className="call-to-action">
          <div>
            Sign up to create a FREE acount to customise and interact with the tools and make ulimited
            changes
            <br></br>
          </div>

          <div>
            <Button variant="contained" color="primary" size="large">
              {" "}
              <Link
                to="./signup"
                style={{ textDecoration: "none", color: "white" }}
              >
                Sign Up
              </Link>
            </Button>
          </div>
        </div>
      </section>
    </>
  );
};
