
import { Link } from "react-router-dom";
import Button from "@material-ui/core/Button";

export const Rundoit = () => {
    return (
      <>
  <header> 
              <h1 class=" ud-text-black p-4 ud-font-bold ud-text-3xl
                  sm:ud-text-4xl
                  md:ud-text-[45px]
                  ud-mb-5">Rundoit tool</h1> 
            </header> 
        <hr />
        {/* <body class="bg-gray-100"> */}

<div class="ud-container ud-mx-auto ud-py-8">

    {/* <!-- Introduction --> */}
    <div class="ud-p-6 ud-my-4 ud-bg-white ud-rounded-lg ud-shadow-md mb-8">
        <h1 class="ud-text-3xl ud-font-bold mb-4">Introducing Rundoit: A Productivity Tool</h1>
        <p class="ud-text-gray-700">Rundoit is an innovative productivity tool designed to combat procrastination and maximize efficiency. At its core, Rundoit leverages Parkinson's Law, which suggests that work expands to fill the time available. By introducing randomness into task selection and timing, Rundoit simplifies decision-making and minimizes the energy required to choose a task.</p>
    </div>

    {/* <!-- Key Features --> */}
    <div class="ud-p-6 ud-bg-white ud-rounded-lg ud-shadow-md mb-8">
        <h2 class="ud-text-2xl ud-font-bold ud-mb-4">Key Features:</h2>
        <ul class="ud-list-disc ud-list-inside ud-text-gray-700">
            <li>Parkinson's Law Integration: Rundoit harnesses Parkinson's Law by randomizing both task selection and timing. This ensures that users focus on completing tasks rather than deliberating over what to do next.</li>
            <li>Random Timer: To encourage immediate action, Rundoit initiates a random timer if users fail to select a task promptly. This feature adds a sense of urgency, nudging users to start working without delay.</li>
            <li>Flexible Time Options: Users have the flexibility to choose between randomized time intervals or set predefined Pomodoro sessions (e.g., 25 minutes, 45 minutes, or 1 hour). This customization caters to individual preferences and work styles.</li>
            <li>Simple Interface: Rundoit offers a user-friendly interface for seamless navigation. Users can effortlessly add their favorite activities or create a to-do list, making it easy to integrate Rundoit into their daily workflow.</li>
        </ul>
    </div>

    {/* <!-- How to Use Rundoit --> */}
    <div class="ud-p-6 ud-my-4 ud-bg-white ud-rounded-lg ud-shadow-md ud-mb-8">
        <h2 class="ud-text-2xl font-bold mb-4">How to Use Rundoit:</h2>
        <ol class="ud-list-decimal list-inside text-gray-700">
            <li>Add Tasks: Start by adding your favorite activities or creating a to-do list within the app.</li>
            <li>Task Association: Optionally, associate default tasks with specific activities you have in mind. For example, designate "Task 1" for writing an email and "Task 2" for fixing a device.</li>
            <li>Initiate Timer: Press the "Random" button to begin the timer. Rundoit will randomly select a task to start, fostering a dynamic and efficient workflow. Alternatively, access the settings to set a specific timer duration if preferred.</li>
        </ol>
    </div>

    {/* <!-- Conclusion --> */}
    <div class="ud-p-6 bg-white ud-rounded-lg ud-shadow-md ud-mb-8">
        <p class="ud-text-gray-700">With Rundoit, you can overcome procrastination, streamline your workflow, and accomplish more in less time. Embrace the power of randomness and productivity with Rundoit today!</p>
    </div>

</div>
{/* 
</body> */}
        <section>
        <div className="call-to-action">
          <div>
            Sign up to create a FREE acount to customise and interact with the tools and make ulimited
            changes
            <br></br>
          </div>

          <div>
            <Button variant="contained" color="primary" size="large">
              {" "}
              <Link
                to="./signup"
                style={{ textDecoration: "none", color: "white" }}
              >
                Sign Up
              </Link>
            </Button>
          </div>
        </div>
      </section>
      </>
    );
  };
  