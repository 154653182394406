import React, { useState } from 'react';
import app from '../../util/firebase';


function TaskForm() {
  const [taskName, setTaskName] = useState('');
  const [startTime, setStartTime] = useState('');

  function handleSubmit(event) {
    event.preventDefault();

    const newTask = {
      name: taskName,
      start_time: startTime,
    };

    app.database().ref('tasks').push(newTask);
  }

  return (
    <form onSubmit={handleSubmit}>
      <label>
        Task name:
        <input
          type="text"
          value={taskName}
          onChange={(event) => setTaskName(event.target.value)}
        />
      </label>
      <label>
        Start time:
        <input
          type="datetime-local"
          value={startTime}
          onChange={(event) => setStartTime(event.target.value)}
        />
      </label>
      <button type="submit">Create task</button>
    </form>
  );
}

export default TaskForm;
