import React, { useState, useEffect } from 'react';
import app from "../../util/firebase";
import TaskForm from './TaskForm';
import TaskProgressBar from './TaskProgressBar';
import TaskFeedback from './TaskFeedback';
import "../../App.css";


function TaskPage({ user }) {
  const [tasks, setTasks] = useState([]); // State to store the tasks
  const [selectedTaskId, setSelectedTaskId] = useState(''); // State to store the selected task ID
  const [editTaskId, setEditTaskId] = useState(''); // State to store the task ID being edited


  const [editedTaskTitle, setEditedTaskTitle] = useState('');

  const { currentUser } = app.auth();
  var user = currentUser;
  
  useEffect(() => {
    // Fetch the tasks from the database on component mount
    const tasksRef = app.database().ref(`users/${user.uid}/tasks`);

    tasksRef.on('value', (snapshot) => {
      const tasksData = snapshot.val();
      if (tasksData) {
        const tasksList = Object.entries(tasksData).map(([taskId, taskData]) => ({
          taskId,
          ...taskData,
        }));
        setTasks(tasksList);
      } else {
        setTasks([]);
      }
    });
  

    return () => {
      tasksRef.off();
    };
  }, [user]);
  // const handleEditTask = (taskId) => {
  //   const taskToEdit = tasks.find((task) => task.taskId === taskId);
  //   if (taskToEdit) {
  //     setEditTaskId(taskId);
  //     setEditedTaskTitle(taskToEdit.title);
  //   }
  // };
  const handleTaskEdit = (taskId) => {
    // Set the task ID being edited
    setEditTaskId(taskId);
    setSelectedTaskId(''); // Clear the selected task ID
  };
  // const handleEditTask = (taskId) => {
  //   const taskToEdit = tasks.find((task) => task.taskId === taskId);
  //   if (taskToEdit) {
  //     setEditTaskId(taskId);
  //     setEditedTaskTitle(taskToEdit.title);
  //   }
  // };
  const handleUpdateTask = (event, taskId) => {
    event.preventDefault();
  
    const taskRef = app.database().ref(`users/${user.uid}/tasks/${taskId}`);
  
    taskRef.child('title').set(editedTaskTitle)
      .then(() => {
        setEditTaskId('');
        setEditedTaskTitle('');
      })
      .catch((error) => {
        console.error('Error updating task:', error);
      });
  };
  const handleEditedTaskTitleChange = (event) => {
    setEditedTaskTitle(event.target.value);
  };


  const handleTaskSelect = (taskId) => {
    // Update the selected task ID
    setSelectedTaskId(taskId);
    setEditTaskId(''); // Clear the edit task ID
  };
  const handleTaskDelete = (taskId) => {
    const confirmDelete = window.confirm('Are you sure you want to delete this task?');
    if (confirmDelete) {
      const updatedTasks = tasks.filter((task) => task.taskId !== taskId);
  
      app.database().ref(`users/${user.uid}/tasks`).set(updatedTasks);
  
      setSelectedTaskId('');
    }
  };



  return (
    <div>
      <h1>Welcome, {user.displayName}!</h1>
      <div className="form-container">
        <TaskForm user={user} />
      </div>
      <div className="task-list-container">
        <h2>Task List</h2>
        {tasks.length === 0 ? (
          <p>No tasks found.</p>
        ) : (
          <div>
            <select value={selectedTaskId} onChange={(e) => handleTaskSelect(e.target.value)}>
              <option value="">Select a task</option>
              {tasks.map((task) => (
                <option key={task.taskId} value={task.taskId}>
                  {task.title}
                </option>
              ))}
            </select>
            {selectedTaskId && (
              <div>
                <h3>{tasks.find((task) => task.taskId === selectedTaskId).title}</h3>
                <p>{tasks.find((task) => task.taskId === selectedTaskId).description}</p>
                <TaskProgressBar taskId={selectedTaskId} />
                <TaskFeedback taskId={selectedTaskId} />
                <button onClick={() => handleTaskDelete(selectedTaskId)}>Delete</button>
                <button onClick={() => handleTaskEdit(selectedTaskId)}>Edit</button>
              </div>
            )}
            {editTaskId && (
              <div>
                <h3>Edit Task: {tasks.find((task) => task.taskId === editTaskId).title}</h3>
                <form onSubmit={handleUpdateTask}>
    <label>
      New Task Title:
      <input
        type="text"
        value={editedTaskTitle}
        onChange={handleEditedTaskTitleChange}
      />
    </label>
    <button type="submit">Update Task</button>
  </form>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
}

export default TaskPage;