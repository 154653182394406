import React from "react";
//how to page
export const Blog = () => {
  return (
    <>
      <hr />
      <body>

<div class="ud-container ud-mx-auto py-12 px-4">
    <h1 class="text-3xl font-bold text-center mb-8">Rundoit: Your Ultimate Productivity Companion</h1>

    <div class="ud-grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
        {/* <!-- Random Mode --> */}
        <div class="ud-p-6 bg-white rounded-lg shadow-md">
            <h2 class="text-xl font-bold mb-4">Random Mode:</h2>
            <p class="text-gray-700">In Random Mode, Rundoit operates at its most spontaneous, randomly selecting tasks and durations to inject an element of surprise and urgency into your workflow. This mode is perfect for those seeking an unpredictable yet dynamic approach to task management.</p>
        </div>

        {/* <!-- Flow Mode --> */}
        <div class="ud-p-6 bg-white rounded-lg shadow-md">
            <h2 class="text-xl font-bold mb-4">Flow Mode:</h2>
            <p class="text-gray-700">Flow Mode is tailored for users who thrive on uninterrupted concentration and deep work sessions. By minimizing interruptions and distractions, Rundoit enables you to enter a state of flow where productivity and creativity flourish. This mode is ideal for tackling complex tasks or creative projects that demand sustained focus.</p>
        </div>
{/* 
        <!-- Start Here Mode --> */}
        <div class="ud-p-6 bg-white rounded-lg shadow-md">
            <h2 class="text-xl font-bold mb-4">Start Here:</h2>
            <p class="text-gray-700">Start Here mode provides a structured starting point for users who prefer a more organized approach to task management. By guiding you through a predefined sequence of tasks, Rundoit helps you kickstart your productivity journey and stay on track towards achieving your goals. Whether you're a seasoned planner or just getting started, Start Here mode offers a roadmap for success.</p>
        </div>
    </div>

    {/* <!-- Refresh Feature --> */}
    <div class="ud-p-6 mt-8 bg-white rounded-lg shadow-md">
        <h2 class="text-xl font-bold mb-4">Refresh:</h2>
        <p class="text-gray-700">Additionally, Rundoit's Refresh feature allows you to reset and rejuvenate your workflow, providing a quick way to clear your mind and refocus on your priorities. With just a click, you can refresh your task list and approach each new challenge with renewed energy and clarity.</p>
    </div>
</div>

</body>

    </>
  );
};
