import React, { useState, useEffect } from 'react';
import app from "../../util/firebase";

function TaskProgressBar({ taskId }) {
  const [task, setTask] = useState(null);
  const { currentUser } = app.auth();
  const uid = currentUser.uid;

  useEffect(() => {
    const taskRef = app.database().ref(`users/${uid}/tasks/${taskId}`);

    taskRef.on('value', (snapshot) => {
      const taskData = snapshot.val();
      setTask(taskData);
    });

    return () => {
      taskRef.off();
    };
  }, [taskId, uid]);

  if (!task) {
    return <p>Loading task...</p>;
  }

  const { title, startDate, duration } = task;
  const progress = calculateProgress(startDate, duration);

  return (
    <div>
      <h2>Name: {title}</h2>
      <p>Started: {startDate}</p>
      <p>Progress: {isNaN(progress) ? 0 : progress}%</p>
      <div className="progress-bar">
        <div className="progress" style={{ width: `${isNaN(progress) ? 0 : progress}%` }}></div>
      </div>
    </div>
  );
}

function calculateProgress(startDate, duration) {
  const startTimestamp = new Date(startDate).getTime();
  const endTimestamp = startTimestamp + duration * 7 * 24 * 60 * 60 * 1000;
  const currentTimestamp = Date.now();

  const totalDuration = endTimestamp - startTimestamp;
  const elapsedDuration = currentTimestamp - startTimestamp;
  const progress = (elapsedDuration / totalDuration) * 100;

  return progress.toFixed(2);
}

export default TaskProgressBar;
