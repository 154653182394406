import firebase from 'firebase/app'
import 'firebase/database'
import 'firebase/firestore'
import "firebase/auth"




const app = firebase.initializeApp({
  apiKey: "AIzaSyAwLjlTUPtHpzlaucCEJzZLKYJK54ZzQ3s",
  authDomain: "test-app-a3821.firebaseapp.com",
  databaseURL: "https://test-app-a3821-default-rtdb.firebaseio.com",
  projectId: "test-app-a3821",
  storageBucket: "test-app-a3821.appspot.com",
  messagingSenderId: "587533423775",
  appId: "1:587533423775:web:0a06a09e8590e4cdb2beaa",
  measurementId: "G-NLLC8RS3YE"
  });


export const auth = app.auth()
export const db = firebase.firestore();

export default app

//Google sign in configuration
const googleProvider = new firebase.auth.GoogleAuthProvider()
export const signInWithGoogle = () => {
  auth.signInWithPopup(googleProvider).then((res) => {
    console.log(res.user)
  }).catch((error) => {
    console.log(error.message)
  })
}