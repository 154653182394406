import React from "react";

// import TodoList from './TodoList';

import Home from "./components/Auth-user/Todos";
import TaskPage from "./components/Auth-user/TaskPage";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";

import { AuthProvider } from "./contexts/AuthContext";

import Form from "./components/Auth-user/Form";
import Login from "./components/Auth-user/Login";
import Dashboard from "./components/Auth-user/Dashboard";
import Signup from "./components/Auth-user/Signup";

import { Homepage } from "./components/Home/Home";
import PrivateRoute from "./components/Auth-user/PrivateRoutes";
// import UploadImage from './components/UploadImage';
import "./App.css";
import "./index.css";

import Navbars from "./components/Navbar";
import { Footer } from "./components/footer";
import { Landing } from "./pages/landing";
import { Blog } from "./pages/Info";
import {ProgressForm} from "./components/Home/Progress"
import {ProgressPage} from "./components/Home/progresspage"
import { Terms } from "./pages/Terms";
import { Privacy } from "./pages/Privacy";
import { About } from "./pages/About";
import { Rundoit } from "./pages/Rundoit"
import { Progress } from "./pages/Progress"
import { Health } from "./pages/Health"
import TaskPages from "./components/Home/TaskPage";

export default function App() {
  return (
    <div className="App">
      <Router>
        <AuthProvider>
          <Navbars />
          <Switch>
            <Route path="/general" component={Homepage} />
            <Route path="/progress" component={ProgressForm} />
            <Route path="/progresspage" component={ProgressPage} />
            <Route path="/taskpage" component={TaskPages} />
            <Route path="/taskuser" component={TaskPage} />


            <Route path="/signup" component={Signup} />
            <Route path="/info" component={Blog} />
            <Route path="/form" component={Form} /> 
            <Route path="/rundoit" component={Rundoit} />
            <Route path="/health-tool" component={Health} />
            <Route path="/progress-tool" component={Progress} />
          
            <Route path="/terms" component={Terms} />
            <Route path="/about" component={About} />
            <Route path="/privacy" component={Privacy} />
            <PrivateRoute exact path="/user" component={Home} />
            <PrivateRoute path="/profile" component={Dashboard} />
            <Route path="/login" component={Login} />
            <Route path="/" component={Landing} />
          </Switch>
        </AuthProvider>
        <Footer />
      </Router>
    </div>
  );
}
