
  import React from 'react';
  import { LineChart, Line, XAxis, YAxis , CartesianGrid, Tooltip, Legend} from 'recharts';

  //used a prop in the component
 /* in the dataKey you need to enter value you getting from data 
  eg. is data contains score you provide that in the datakey 
  */
  
  const ProgressChart = ({ data }) => {
    return (
      <LineChart
        width={600}
        height={600}
        data={data}
        margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
      >
           <CartesianGrid strokeDasharray="3 3" />
            <Tooltip />
            <Legend />
        <XAxis dataKey="date" />
        <YAxis />
        
        <Line type="monotone" dataKey="score" stroke="#8884d8"  />
      </LineChart>
    );
  };
  
  export default ProgressChart;