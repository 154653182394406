import React, { useState } from 'react';
import app from "../../util/firebase";
import "../../App.css";

function TaskForm({ user }) {
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [startDate, setStartDate] = useState('');
 

  const handleSubmit = (event) => {
    event.preventDefault();

    const { currentUser } = app.auth();
    var user = currentUser;
    var uid = user.uid;

    if (title && description && startDate) {
      // Reference the tasks collection for the current user
      const tasksRef = app.database().ref(`users/${user.uid}/tasks`);
      
      // Push a new task object to the tasks collection with user ID included
      tasksRef.push({
        title,
        description,
        startDate,
       
        userId: user.uid, // Include the user ID in the task data
      });

      // Reset the form
      setTitle('');
      setDescription('');
      setStartDate('');
     
    }
  };

  const handleTitleChange = (event) => {
    setTitle(event.target.value);
  };

  const handleDescriptionChange = (event) => {
    setDescription(event.target.value);
  };

  const handleStartDateChange = (event) => {
    setStartDate(event.target.value);
  };

 

  return (
    <div>
      <h2>Create Task</h2>
      <form onSubmit={handleSubmit} className="task-form">
  <div className="form-group">
    <label htmlFor="title">Title:</label>
    <input type="text" id="title" value={title} onChange={handleTitleChange} />
  </div>
  <div className="form-group">
    <label htmlFor="description">Description:</label>
    <textarea id="description" value={description} onChange={handleDescriptionChange}></textarea>
  </div>
  <div className="form-group">
    <label htmlFor="startDate">Start Date:</label>
    <input type="date" id="startDate" value={startDate} onChange={handleStartDateChange} />
  </div>
  <button type="submit">Create</button>
</form>
    </div>
  );
}

export default TaskForm;
