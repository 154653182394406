import React, { useState, useEffect } from "react";
import db from "../../util/firebase";
import ProgressChart from "./progresschart";
import { ProgressForm } from "./Progress";


export const ProgressPage = () => {
  const [data, setData] = useState([]);

  useEffect(() => {
    db.firestore()
      .collection("progress")
      .onSnapshot((snapshot) => {
        const newData = snapshot.docs.map((doc) => ({
          ...doc.data(),
          key: doc.id,
        }));
        setData(newData);
      });
  }, []);

  return (
    <>
      <ProgressForm />

      <ProgressChart data={data} />
    </>
  );
};

// export const ProgressPage = () => {
//   const [data, setData] = useState([]);

//     useEffect(() => {
//       const fetchData = async () => {
//         const docRef = firestore.collection('progress').doc('documentId');
//         docRef.get().then((doc) => {
//           if (doc.exists) {
//             setData(doc.data());
//           } else {
//             console.log("No such document!");
//           }
//         }).catch(function(error) {
//           console.log("Error getting document:", error);
//         });
//       };
//       fetchData();
//   }, []);

//   return (
//     <div>
//       <ProgressChart data={data} />
//     </div>
//   );
// };
